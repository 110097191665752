// React
import React from 'react';

// Gatsby
import { graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages
import { StickyContainer } from 'react-sticky';

// Utilities
import { useTheme } from 'hooks';

// Components
import SEO from 'components/seo';
import ProductHero from 'components/_product/productHero';
import ProductDescription from 'components/_product/productDescription';
import ProductBrewGuide from 'components/_product/productBrewGuide';
import RelatedProducts from 'components/_shop/relatedProducts';
import RelatedStories from 'components/_stories/relatedStories';
import Subscription from 'components/subscription';
import Newsletter from 'components/newsletter';

const ProductPresale = props => {
    const {
        location,
        pageContext,
        pageContext: { locale },
        data: {
            datoCmsPresaleProduct,
            datoCmsPresaleProduct: {
                seoMetaTags,
                showSubscription = false,
                showNewsletter = false,
                showBrewGuides = false,
                relatedProducts = [],
                relatedBlogPost = [],
                sku,
                price,
                images,
                weight,
                variants,
                theme,
            },
            datoCmsPageShop,
        },
    } = props;

    // Set theme
    const productTheme = useTheme(theme);

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />
                <StickyContainer>
                    <ContentWrapper>
                        <ProductHero
                            {...{
                                parent: datoCmsPageShop,
                                parentTheme: datoCmsPageShop.theme,
                                theme: productTheme,
                                product: datoCmsPresaleProduct,
                                productVariations: [
                                    {
                                        sku,
                                        price,
                                        weight,
                                        images,
                                        variants,
                                    },
                                ],
                                location,
                                pageContext,
                            }}
                        />

                        <ProductDescription
                            {...{
                                theme: productTheme,
                                product: datoCmsPresaleProduct,
                                pageContext,
                            }}
                        />
                        {showBrewGuides && (
                            <ProductBrewGuide {...{ location, pageContext }} />
                        )}
                        {relatedProducts.length > 0 && (
                            <RelatedProducts
                                {...{
                                    pageContext,
                                    location,
                                    products: relatedProducts,
                                }}
                            />
                        )}
                        {relatedBlogPost.length > 0 && (
                            <RelatedStories
                                {...{
                                    pageContext,
                                    location,
                                    stories: relatedBlogPost,
                                }}
                            />
                        )}
                        {showNewsletter && (
                            <Newsletter
                                {...{
                                    pageContext,
                                    location,
                                }}
                            />
                        )}
                        {showSubscription && (
                            <Subscription
                                {...{
                                    pageContext,
                                    location,
                                }}
                            />
                        )}
                    </ContentWrapper>
                </StickyContainer>
            </s.layout.PageContainer>
        </>
    );
};

export default ProductPresale;

export const productPresaleQuery = graphql`
    query productPresaleQuery($itemId: String!) {
        datoCmsPresaleProduct(id: { eq: $itemId }) {
            ...ProductPresaleFragment
            relatedProducts {
                ... on DatoCmsFilterCoffee {
                    ...ProductFilterCoffeeFragment
                }
                ... on DatoCmsEspresso {
                    ...ProductEspressoFragment
                }
                ... on DatoCmsEquipment {
                    ...ProductEquipmentFragment
                }
                ... on DatoCmsOtherProduct {
                    ...ProductOtherProductFragment
                }
            }
        }
        datoCmsPageShop {
            theme {
                ...Theme
            }
            slug
        }
    }
`;

const ContentWrapper = styled.div`
    position: relative;
    z-index: ${s.layout.zIndex.content};
`;
